import React from "react";
import { useStaticQuery, graphql } from "gatsby";

function useMetadata() {
  const data = useStaticQuery(graphql`
    query MetadataQuery {
      site {
        siteMetadata {
          title
          description
          about
          siteUrl
          social {
            twitter
          }
        }
      }
    }
  `);

  return data.site.siteMetadata;

  // return function nameBlog(id) {
  //   try {
  //     const blog = filter(
  //       data.postgres.allBlogs.edges,
  //       ({ node }) => node.id == id
  //     );
  //     return blog[0].node.title;
  //   } catch (e) {
  //     return id;
  //   }
  // };
}

export default useMetadata;
