import React from "react";
import { H5, Button, Input } from "../style/theme";

function MailingList() {
  return (
    <div>
      <form
        id="sender-subscribe"
        action="https://app.sender.net/forms/sender_subscription/10074/043d08df"
        method="POST"
      >
        <H5>النشرة البريدية</H5>
        <p>
          تتضمن نشرتنا البريدية مختاراتٍ لبعض التدوينات المميزة، بالإضافة إلى
          بعض الأخبار، النصائح، والأدوات التي قد تُساعد وتُلهم المدونين.
        </p>
        <div id="subscription-form-fields">
          <Input
            name="email"
            type="email"
            id="email"
            data-label="Email"
            placeholder="البريد الإلكتروني"
            required
            css={{ width: "100%" }}
          />
        </div>
        <Button type="submit" css={{ marginTop: "0.7rem" }}>
          اشترك
        </Button>
      </form>
    </div>
  );
}

export default MailingList;
